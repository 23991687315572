/**
 * Corresponds to SCSS theme palettes.
 * Used in charts, etc.
 */

export interface Palette {
  base: string
  surface: string
  element: string
  item: string
  border: string
  primary: string
  text: string
  textDim: string
  textFaint: string
  highlight: string
  dream: string
  tag: string
  person: string
  thought: string
  quote: string
  fact: string
  excerpt: string
  lesson: string
  idea: string
}

const lightPalette: Palette = {

  // Theme
  base: '200, 3%, 100%',
  surface: '200, 3%, 98%',
  element: '200, 3%, 95%',
  item: '200, 3%, 93%',
  border: '200, 3%, 91%',

  primary: '30, 100%, 68%',

  // Text
  text: '200, 3%, 10%',
  textDim: '200, 3%, 62%',
  textFaint: '200, 3%, 77%',

  // Highlights
  highlight: '357, 100%, 60%',

  // Dreams
  dream: '52, 100%, 50%',

  // Tags/People
  tag: '220, 100%, 67%',
  person: '154, 100%, 43%',

  // Gems
  thought: '35, 100%, 75%',
  quote: '267, 100%, 79%',
  fact: '195, 100%, 60%',
  excerpt: '15, 100%, 75%',
  lesson: '170, 85%, 47%',

  // Ideas
  idea: '35, 100%, 68%',
}

const darkPalette: Palette = Object.assign({}, lightPalette, {

  // Theme
  base: '200, 3%, 10%',
  surface: '200, 3%, 15%',
  element: '200, 3%, 22%',
  item: '200, 3%, 24%',
  border: '200, 3%, 27%',

  // Text
  text: '200, 3%, 90%',
  textDim: '200, 3%, 60%',
  textFaint: '200, 3%, 40%',
})

export const THEME_PALETTES: { light: Palette, dark: Palette } = {
  light: lightPalette,
  dark: darkPalette,
}

function hsl2rgb(h: number, s: number, l: number) {
  let r, g, b

  if (!Number.isFinite(h))
    h = 0
  if (!Number.isFinite(s))
    s = 0
  if (!Number.isFinite(l))
    l = 0

  h /= 60
  if (h < 0)
    h = 6 - (-h % 6)
  h %= 6

  s = Math.max(0, Math.min(1, s / 100))
  l = Math.max(0, Math.min(1, l / 100))

  const c = (1 - Math.abs((2 * l) - 1)) * s
  const x = c * (1 - Math.abs((h % 2) - 1))

  if (h < 1) {
    r = c
    g = x
    b = 0
  }
  else if (h < 2) {
    r = x
    g = c
    b = 0
  }
  else if (h < 3) {
    r = 0
    g = c
    b = x
  }
  else if (h < 4) {
    r = 0
    g = x
    b = c
  }
  else if (h < 5) {
    r = x
    g = 0
    b = c
  }
  else {
    r = c
    g = 0
    b = x
  }

  const m = l - c / 2
  r = Math.round((r + m) * 255)
  g = Math.round((g + m) * 255)
  b = Math.round((b + m) * 255)

  return { r, g, b }
}

function hslStr2rgbStr(hslStr: string): string {
  const [h, s, l] = hslStr.split(',').map((s, idx) => idx ? Number.parseInt(s.slice(0, -1)) : Number.parseInt(s))
  const { r, g, b } = hsl2rgb(h, s, l)
  return `${r}, ${g}, ${b}`
}

export function hslPalette2rgb(hslPalette: Palette): Palette {
  const rgbPalette: any = {}
  for (const key of Object.keys(hslPalette)) {
    rgbPalette[key] = hslStr2rgbStr(hslPalette[key as keyof Palette])
  }
  return rgbPalette as Palette
}

export const colorPalettes: { light: { hsl: Palette, rgb: Palette }, dark: { hsl: Palette, rgb: Palette } } = {
  light: {
    hsl: lightPalette,
    rgb: hslPalette2rgb(lightPalette),
  },
  dark: {
    hsl: darkPalette,
    rgb: hslPalette2rgb(darkPalette),
  },
}

export const themeIcons = {
  light: 'theme-light',
  dark: 'theme-dark',
  system: 'theme-auto',
}
export const themeLabels = {
  light: 'Light',
  dark: 'Dark',
  system: 'Auto',
}
